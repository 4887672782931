<template>
<div class="info">
            <h2>Es posible ahorrar dinero al financiar tu vehículo</h2>
            <ul>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square-fill" style="color:#03658c" viewBox="0 0 16 16">
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
                    </svg>
                 </div>
                 <li>
                   Planifica de forma precisa los costos de un carro. Te ayudamos a ahorrar de forma sencilla encontrando seguros que reduzcan la cuota de tu crédito. 
                 </li>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square-fill" style="color:#03658c" viewBox="0 0 16 16">
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
                     </svg>
                </div>
                <li>
                    Sé inteligente con el préstamo de tu vehículo. Es fundamental que compares tasas de interés y que aprendas a calcular cómo funcionan durante la vigencia del crédito. 
                </li>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square-fill" style="color:#03658c" viewBox="0 0 16 16">
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
                    </svg>
                </div>
                <li> 
                    Lo más importante, elige un carro a tu medida. Selecciona un modelo que se adapte a tus necesidades; elige los componentes que sean importantes para tu confort.  Así, evitas hacer un préstamo por un producto que tenga funciones innecesarias.
                </li>
            </ul>
            <button-registro></button-registro>
        </div>
</template>

<script>
import ButtonRegistro from './layouts/ButtonRegistro.vue'

export default {
    components: {
      ButtonRegistro
   }

}
</script>

<style scoped>
.info {
    margin-top: 3rem;
    margin-left: 20px;
    margin-right: 20px;
}

.info h2 {
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: #03658c;
    text-align: center;
}

.info ul{
    display: grid;
    padding-left: 0.8rem;
    padding: 0;
    grid-template-columns: 16px minmax(15rem, 35rem);
    column-gap: 0.6rem;
    row-gap: 1rem;
    list-style: none;   
}

@media (min-width: 43rem){
    .info ul {
       grid-template-columns: 16px 38rem;
    }

    .info h2 {
       font-size: 1.7rem;
    } 

    .info li {
      font-size: 1.05rem;
    }
}

@media (min-width: 53rem){
    .info {
        margin-right: 2rem;
        max-width: 355px;
    }
 
    .info h2 {
        font-size: 1.5rem;
        padding-right: 2rem;
    } 

    .info ul {
        grid-template-columns: 16px 20rem;
    }
}

@media (min-width: 59rem){
    .info {
        margin-right: 2rem;
        max-width: 375px;
    }
    .info ul {
        grid-template-columns: 16px 22rem;
    }
}

@media (min-width: 63.40rem){
    .info {
        max-width: 550px;
        margin-right: 2rem;
    }

    .info h2 {
       font-size: 1.7rem;
    } 

     .info ul {
        grid-template-columns: 16px 30rem;
    }
  
}
</style>