<template>
    <the-navigation></the-navigation>
    <main>
      <form-simulador></form-simulador>
      <proceso-credito></proceso-credito>
    </main>
    <the-footer></the-footer> 
</template>

<script>
import TheNavigation from './components/layouts/TheNavigation.vue';
import FormSimulador from './components/FormSimulador.vue';
import ProcesoCredito from './components/ProcesoCredito.vue';
import TheFooter from './components/layouts/TheFooter.vue';


export default {
    components: {
       TheNavigation,
       FormSimulador,
       ProcesoCredito,
       TheFooter 
    }
    
} 
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

* {
    box-sizing: border-box;
}

html {
   height: 100%;
}

body { 
    background-color: #f7f7f7;
    font-family: 'Oswald', Helvetica, sans-serif;
    margin: 0;
    display: grid;
    grid-template-rows: 4.2rem auto;
    grid-template-areas: "header"
                          "main";
    height: 100%;
}

main {
    grid-area: main;
    padding-top: 6.6rem;
}


@media (min-width: 22rem){
    main {
        padding-top: 5rem; 
    }
}


</style>
