import { createApp } from 'vue';

import App from './App.vue';

/*const Simulador = () => 
  import('./components/Simulador')
; */



/*const store = createStore({
   state() {
    return {

    };
   }
});*/

const app = createApp(App);


//app.use(store);

app.mount('#app');
