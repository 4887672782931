<template>
    <section class="simulador">
        <form @submit.prevent="submitForm" id="form-simulador">
            <h1>Simulador crédito de vehículo</h1>
            <div class="form-simulador__tipo">
                <span class="form-simulador__title">Tipo de auto:</span>
                <button  
                    class="button-tipo-auto"
                    :class="buttonNewClasses"
                    @click="buttonSelected('nuevo')"
                >Nuevo</button>
                <button 
                    class="button-tipo-auto"
                    :class="buttonUsedClasses"
                    @click="buttonSelected('usado')"
                >Usado</button>
            </div>
            <div class="form-simulador__valor">
                <span class="form-simulador__title">Valor del auto:</span>
                <span>$ {{ valorAutoSeparadorMiles }}</span>
                <input 
                    v-model="valorAuto" 
                    id="valor-auto"  
                    class="slider" 
                    type="range" 
                    min="10000000" 
                    max="200000000" 
                    step="1000000">  
            </div>
            <div class="form-simulador__inicial">
                <span class="form-simulador__title">Cuota inicial:</span>
                <span>$ {{ valorInicialSeparadorMiles }}</span>
                <input 
                    v-model="cuotaInicial" 
                    id="cuota-inicial" 
                    class="slider" 
                    type="range" 
                    min="0" 
                    max="100000000" 
                    step="1000000">
            </div>
            <div class="form-simulador">
                <span class="form-simulador__title" >Plazo:</span>
                <span> {{ plazo }} meses</span>
                <input 
                    v-model="plazo" 
                    id="plazo-credito" 
                    class="slider" 
                    type="range" 
                    min="12" 
                    max="72" 
                    step="12">
             </div>
             <div class="form-simulador__monto-prestamo">
                <span class="form-simulador__title">Monto del préstamo:</span>
                <span>$ {{ valor }}</span>
            </div>
            <div class="form-simulador__boton-enviar">
                <a href="#plan-financiacion-credito"><button type="button" class="btn" @click="calcularCuota">SIMULA TU CRÉDITO</button></a>
            </div>
        </form>
        <info-recomendaciones></info-recomendaciones>
    </section>
    <section id="plan-financiacion-credito">
        <div class="planCredito__resultado" v-if="valorCuota">
            <h2>Plan financiación crédito</h2>
            <p>Monto del préstamo: <span>$ {{ valor }}</span></p>
            <hr>
            <p><span>{{ plazo }}</span> cuotas mensuales de: <span>$ {{ valorCuota }}</span></p>
            <hr>
            <a href="https://registro.abbaseguros.co"  class="btn">SOLICITAR CRÉDITO</a>
        </div>
    </section>
  
</template>

<script>    
import InfoRecomendaciones from './InfoRecomendaciones.vue'


export default {
 components: {
    InfoRecomendaciones
 },
 data(){
        return{
           valorAuto: 25000000,
           cuotaInicial: 0,
           plazo: 72,
           buttonNewSelected: true,
           buttonUsedSelected: false,
           valorFinanciar: 0,
           valorCuota: null
        };
    },
    computed:{
        buttonNewClasses(){
             return { active : this.buttonNewSelected}
        },
        buttonUsedClasses(){
            return { active: this.buttonUsedSelected}
        },
        valor(){
           if(this.valorAuto - this.cuotaInicial < 0){
            return 0;
           }
          return new Intl.NumberFormat().format(this.valorAuto - this.cuotaInicial);
        },
        valorAutoSeparadorMiles(){
            return new Intl.NumberFormat().format(this.valorAuto);
        },
        valorInicialSeparadorMiles(){
            return new Intl.NumberFormat().format(this.cuotaInicial);
        }

    },
    methods:{
        buttonSelected (button){
         if(button === 'usado'){
           this.buttonUsedSelected = true;
           this.buttonNewSelected = false;
         }if(button === 'nuevo'){
           this.buttonNewSelected = true;
           this.buttonUsedSelected = false;
         }
        },
        calcularCuota(){
           let tasaInteres = 0.0148;
           this.valorFinanciar = this.valorAuto - this.cuotaInicial;
           this.valorCuota = Math.round((tasaInteres * this.valorFinanciar) / (1 - (1 + tasaInteres) ** -this.plazo));
           this.valorCuota = new Intl.NumberFormat().format(this.valorCuota);
        }  
    }
      
}
</script>


<style scoped>
.seccion-principal {
    display: grid;
}

.seccion-principal img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.simulador {
    display: flex;
    flex-wrap: wrap;
    /*margin-top: 2rem;*/
    justify-content: center;
    color: #53565a;
}

#form-simulador {
    margin-top: 3.5rem;
    display: grid;
    background-color: #ffffff;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(2, minmax(9.5rem, 12.5rem));
    grid-template-rows: minmax(auto, 60px);
    height: 31rem;
    font-size: 1rem; 
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding-bottom: 1rem;
    order:2;
}
 
#form-simulador h1 {
    padding: 15px;
    width: 100%;
    text-align: center;
    align-self: start;
    grid-column: span 2; 
    background: #03658c;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
}

#form-simulador > div{
    display: grid;
    justify-items: center;
    row-gap: 0.3rem;
}

#form-simulador input {
   width: 150px;
}

.form-simulador__title {
    color: #13678a;
    font-weight: bold;
}

.form-simulador__tipo > span{ 
   padding-bottom: 0.5rem;
}

.form-simulador__monto-prestamo {
    text-align: center;
}

 .form-simulador__monto-prestamo,
 .form-simulador__boton-enviar{
    grid-column: span 2; 
    justify-self: center;
 }

.button-tipo-auto{
    color: #03658c;
    border: 1px solid #03658c;
    padding: 6px 12px;
    border-radius: 0.25rem;
    background: white;
}

.button-tipo-auto:hover,
.button-tipo-auto:focus,
.button-tipo-auto:target,
.button-tipo-auto:active {
    background: #03658c;
    border-color: #03658c;
    color: white;
}

.button-tipo-auto:focus,
.btn:focus{
   outline: none;
}    

.active {
    background: #03658c;
    border-color: #03658c;
    color: white;
}

.btn:hover,
.btn:focus,
.btn:target,
.btn:active {
    background: #f29f05;
    border-color: #f29f05;
}

.form-simulador__boton-enviar .btn {
   background: #f29f05;
   border-color: #f29f05;
   font-weight: bold;
   padding: 10px 20px;
   font-size: 1.05rem;
   color: white;
}

#plan-financiacion-credito {
    display: flex;
    justify-content: center;
    justify-items: center;
    background-color: #f7f7f7;
    margin-bottom: 2rem;
}

.form-simulador__tipo{
    grid-template-columns: auto; 
}

.form-simulador__tipo > span {
    grid-column: span 2;
}

.planCredito__resultado{
    display: grid;
    grid-template-columns: minmax(19rem, 35rem);
    justify-content: center;
    justify-items: center;
    background-color: #ffffff;
    height: 17rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    margin-top: 3rem;
    color: #53565a;
}

.planCredito__resultado h2{
    padding: 10px;
    width: 100%;
    margin-bottom: 0;
    text-align: center;
    background: #03658c;
    color:white;
    font-weight: bold;
    font-size: 1.2rem;
}


.planCredito__resultado p {
    margin-top: 1rem;
    text-align: center;
}

.planCredito__resultado span {
    font-weight: bold;
}

.planCredito__resultado hr {
    width: 25%; 
    border-bottom: 3px solid #276cd3;
    margin: 0;
}

.btn{
    margin-top: 2.3rem;
    background: #f29f05;
    border-color:#f29f05;
    font-weight: bold;
    padding: 10px 20px;
    font-size: 1.05rem;
    color: #ffffff;
}

.btn:focus{
   outline: none;
}  

.btn:hover,
.btn:active,
.btn:focus,
.btn:target {
    background: #f29f05;
    border-color: #f29f05;
}

@media (min-width: 27rem){
  #form-simulador h1 {
       font-size: 1.5rem;
       border-top-left-radius: 8px;
       border-top-right-radius: 8px;
  }

   #form-simulador {
       border-radius: 8px;
   }

   .planCredito__resultado {
       border-radius: 8px;
       font-size: 1.05rem;
   }

    .planCredito__resultado p{
       font-size: 1.05rem;
   }

   .planCredito__resultado h2{
       border-top-left-radius: 8px;
       border-top-right-radius: 8px;
       font-size: 1.5rem;
   }

}

@media (min-width: 43rem){
    #form-simulador {
        font-size: 1.05rem;
    }
}

@media (min-width: 53rem){
    .seccion-principal img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
       
    .simulador {
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 2rem;
    }

    /*#form-simulador {
        order: 2;
    }*/

}

@media (min-width: 63.37rem){
   .form-simulador__tipo{
    padding-top: 2rem;
   }

   .form-simulador__valor{
    padding-top: 2rem;
   }

}
 
</style>