<template>
  <div>
    <a href="https://registro.abbaseguros.co" class="btn">SOLICITAR CRÉDITO</a>
  </div>
</template>

<style scoped>
div{
    width: 100%;
    display: grid;
    place-items: center;
    margin-top: 2rem;
}

.btn {
   background: #f29f05;
   border-color: #f29f05;
   font-weight: bold;
   padding: 10px 20px;
   font-size: 1.05rem;
   color: white;
}

.btn:focus{
   outline: none;
}  

.btn:hover,
.btn:focus,
.btn:target,
.btn:active {
    background: #f29f05;
    border-color: #f29f05;
}

</style>