<template>
    <section class="planCredito__proceso">
            <article>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" color="#03658c" fill="currentColor" class="bi bi-check-circle-fill icono" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                </svg>
                <div>
                    <h3>Solicita tu crédito</h3>
                    <p>Solicita tu crédito en línea. Una sola solicitud te permite estudiar la posibilidad de obtener la aprobación con tres bancos diferentes al mismo tiempo: </p>
                    <div class="info-logos">
                        <img src="../../public/logoSufi.png" alt="Logo sufi" class="logo-sufi">
                        <img src="../../public/logoOccidente.png" alt="Logo occidente" class="logo-occidente">
                        <img src="../../public/logoFinandina.png" alt="Logo finandina" class="logo-finandina">
                    </div>
                </div>
            </article>
            <article>
                <BIconEnvelope class="icono icono-solicitud"/>
                <div>
                    <h3>Envia la solicitud de crédito</h3>
                    <p>En ABBA consultor facilitamos la solicitud de tu crédito haciendo el proceso 100% en linea, sin salir de tu casa y en cualquier momento.
                    </p>
                </div>
            </article>
            <article>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" color="#03658c" fill="currentColor" class="bi bi-bar-chart-fill icono" viewBox="0 0 16 16">
                <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z"/>
                </svg>
                <div>
                    <h3>Respuesta</h3>
                    <p>Cuando tengas los resultados de tu solicitud, nuestro equipo de asesoría te puede ayudar a elegir la mejor opción para ti.</p>
                </div>
            </article>
            <article>
                 <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"  color="#03658c" fill="currentColor" class="bi bi-car-front icono" viewBox="0 0 16 16">
                    <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z"/>
                    <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z"/>
                </svg>
                <div>
                    <h3>Proceso de desembolso</h3>
                    <p>Es el momento de disfrutar. Cuando desembolsen tu dinero estarás a punto de tener en tus manos lo que tanto has soñado. Disfruta, te lo mereces.</p>
                </div>
            </article>
    </section>
    <button-registro></button-registro>
</template>

<script>
import { BIconEnvelope } from 'bootstrap-icons-vue';
import ButtonRegistro from './layouts/ButtonRegistro.vue'

export default {
   components: {
    BIconEnvelope,
    ButtonRegistro
   }
}
</script>

<style scoped>
.planCredito__proceso {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 2.5rem;
    align-items: flex-start;
    justify-content: center;
    /*margin-top: 4rem;*/
}

.planCredito__proceso article {
    display: grid;
    /*grid-template-columns: 30px minmax(20rem, 25rem);*/
    grid-template-columns: 30px minmax(14.5rem, 25rem);
    grid-auto-rows: minmax(12rem, auto);
    column-gap: 0.8rem;
    /*height: 15rem;*/
    background-color:#ffffff;
    color: #53565a;
    padding: 1.6rem 1rem;
}


.planCredito__proceso h3{
    font-weight: bold;
    font-size: 1rem;
}

article .icono{
   align-self: center;
}

article div {
   align-self: center;
   margin: 0;
}

article p {
    margin: 0;
}

.info-logos {
    text-align: center;
}

article img {
    display: inline-block;
    height: 2rem;
    margin-right: 0.5rem;
}

.logo-sufi{
    height: 4rem;
}

.logo-occidente {
    height: 5rem;
}

.icono-solicitud{
    font-size: 30px;
    color: #03658c;
}

@media (min-width: 27rem){
    .planCredito__proceso h3{
        font-weight: bold;
        font-size: 1.1rem;
    }

    article div {
        font-size: 1.05rem;
    }
}

@media (min-width: 63rem){
   .planCredito__proceso article {
       grid-auto-rows: 261px;
    }
}

</style>
